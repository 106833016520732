export const serviceDescriptionData = [
  {
    label: 'Donor Eggs',
    description: 'A procedure in which a woman donates eggs to help an individual or a couple conceive. Donor eggs is an option for women who have experienced ovarian failure but can still carry a pregnancy.',
  },
  {
    label: 'Donor Sperm',
    description: 'A procedure in which a man donates semen to help an individual or a couple conceive. Donor sperm is an option for men who have no viable sperm, or for women who do not have a male partner.',
  },
  {
    label: 'Embryo Freezing ',
    description: 'Eggs within the body are stimulated, matured, and collected from the ovaries. Through in vitro fertilization (IVF), the eggs are combined with sperm to create embryos. The embryos are then frozen and stored for later use.',
  },
  {
    label: 'Gestational Surrogacy',
    description: 'This is an arrangement in which a woman (the gestational carrier) carries a pregnancy for a couple (the intended parents) who are not able to do so. In gestational surrogacy, the baby is not genetically related to the carrier. The embryo that is used is either created from the gametes of the intended parents and/or donor gametes.',
  },
  {
    label: 'Oocyte Banking',
    description: 'Eggs within the body are stimulated, matured, and retrieved from the ovaries and then frozen unfertilized and stored for later use.',
  },
  {
    label: 'Ovarian Tissue Cryopreservation (adult)',
    description: 'An ovary, or part of an ovary, is surgically removed from the body, and the tissue is cut into thin pieces which are then frozen and stored for future use.',
  },
  {
    label: 'Ovarian Tissue Cryopreservation (pediatrics) ',
    description: 'An ovary, or part of an ovary, is surgically removed from the body, and the tissue is cut into thin pieces which are then frozen and stored for future use. This the only available fertility preservation option for girls who have not yet entered puberty.',
  },
  {
    label: 'Sperm Banking',
    description: 'The freezing and storage of semen at a sperm banking facility until the patient is ready to use it for family building.',
  },
  {
    label: 'Sperm Extraction/Biopsy',
    description: 'Surgical removal of sperm from the testicular tissue. Sperm that are found are extracted from the tissue, collected, frozen and stored.',
  },
  {
    label: 'Testicular Tissue Cryopreservation (pediatric)',
    description: 'An option for prepubertal patients who are not producing sperm and therefore are unable to preserve a semen sample. This involves taking testicular tissue and freezing it to try to make sperm from it later.',
  },
  {
    label: 'Uterus Transplant',
    description: 'Women who lack a functional uterus can receive a uterus from either a living or deceased donor. This is an experimental procedure that allows a woman who could not otherwise carry a pregnancy, to give birth.',
  },
]
