const servicesMapper = (service) => {

  let translatedService = null;

  switch(service) {
    case 'embryoFreezing':
      translatedService = 'Embryo Freezing';
      break;
    case 'eggFreezing':
      translatedService = 'Egg Freezing';
      break;
    case 'spermBanking':
      translatedService = 'Sperm Banking';
      break;
    case 'surgicalSpermRetrieval':
      translatedService = 'Surgical Sperm Retrieval';
      break;
    case 'ovarianTissueCryopreservationthree':
      translatedService = 'Ovarian Tissue Cryopreservation';
      break;
    case 'donorEggs':
      translatedService = 'Donor Eggs';
      break;
    case 'eggEmbryoStorage':
      translatedService = 'Egg Embryo Storage';
      break;
    case 'ovarianTissueStorage':
      translatedService = 'Ovarian Tissue Storage';
      break;
    case 'longTermEggembryoStorage':
      translatedService = 'Long Term Egg/Embryo Storage';
      break;
    case 'donorSperm': 
      translatedService = 'Donor Sperm';
      break;
    case 'donorEmbryo':
      translatedService = 'Donor Embryo';
      break;
    case 'testicularTissueCryopreservation':
      translatedService = 'Testicular Tissue Cryopreservation';
      break;
    case 'longTermSpermStorage':
      translatedService = 'Long Term Sperm Storage';
      break;
    case 'fertilityTesting':
      translatedService = 'Fertility Assessment';
      break;
    case 'gestationalSurrogacytwo':
      translatedService = 'Gestational Surrogacy';
      break;
    case 'mentalHealthCounseling':
      translatedService = 'Mental Health Counseling';
      break;
    default:
      translatedService = '';
      break;
  }

  return translatedService;
}

const filterServices = (selection, cards) => {
  const filteredCards = cards.filter(eaCard => {
    if (eaCard.services !== undefined) {
      if (eaCard.services.some(service => selection.includes(service))) {
        return eaCard;
      }
    }
  });

  return filteredCards;
}

const filterNames = (selection, cards) => {
  const filteredCards = cards.filter(eaCard => {
    if (eaCard.clinic_practice_name !== undefined || eaCard.doctor !== undefined) {
      if (selection.includes(eaCard.clinic_practice_name) || selection.includes(eaCard.doctor)) {
        return eaCard;
      }
    }
  });

  return filteredCards;
}

export { 
  servicesMapper, 
  filterServices,
  filterNames
};