import React from 'react';
import styled from 'styled-components';
import { Collapse } from 'antd';
import { serviceDescriptionData } from './search-description.data';

const { Panel } = Collapse;

const DescriptionModalInner = () => (
  <DescriptionModalInnerContainer>
    <Collapse defaultActiveKey={['0']} accordion={true}>
      {serviceDescriptionData.map((eaData, index) => {
        return (
          <Panel header={eaData.label} key={`${index}`}>
            {eaData.description}
          </Panel>
        )
      })}
    </Collapse>
  </DescriptionModalInnerContainer>
)

export default DescriptionModalInner;

const DescriptionModalInnerContainer = styled.div``;



