const gender = [
  {value: 'male', label: 'Male'},
  {value: 'female', label: 'Female'},
  {value: 'all', label: 'All Genders/Not Listed'},
];

const stage = [
  {value: 'pre-treatment', label : 'Pre-treatment'},
  {value: 'post-treatment', label : 'Post-treatment'},
  {value: 'unsure', label : 'Unsure'},
];

const type = [
  { value: 'preservation', label: 'Preservation' },
  { value: 'building', label: 'Building' },
]

const puberty = [
  { value: 'have', label: 'Have' },
  { value: 'have-not', label: 'Have Not' },
]

const services = [
  { 
    value: 'ovarianTissueCryopreservationthree', 
    label: 'Ovarian Tissue Cryopreservation', 
    gender: 'female',
    stage: 'pre-treatment',
    type: 'preservation',
    puberty: '',
  },
  { 
    value: 'testicularTissueCryopreservation', 
    label: 'Testicular-Tissue Cryopreservation',
    gender: 'male', 
    stage: 'pre-treatment',
    type: 'preservation',
    puberty: 'have not',
  },
  { 
    value: 'eggFreezing', 
    label: 'Egg Freezing',
    gender: 'female',
    stage: 'post-treatment',
    type: 'preservation',
    puberty: 'have',
  },
  { 
    value: 'embryoFreezing', 
    label: 'Embryo Freezing',
    gender: 'female',
    stage: 'post-treatment',
    type: 'preservation',
    puberty: 'have',
  },
  { 
    value: 'spermBanking', 
    label: 'Sperm Banking',
    gender: 'male',
    stage: '',
    type: 'preservation',
    puberty: 'have',
  },
  { 
    value: 'surgicalSpermRetrieval', 
    label: 'Surgical Sperm Retrieval & Freezing',
    gender: 'male',
    stage: '',
    type: 'preservation',
    puberty: 'have',
  },
  { 
    value: 'longTermOvarianTissueStorage', 
    label: 'Long-Term Ovarian Tissue Storage',
    gender: 'female',
    stage: 'post-treatment',
    type: 'preservation',
    puberty: '',
  },
  { 
    value: 'longTermEggembryoStorage', 
    label: 'Long-Term Egg/Embryo Storage',
    gender: 'female',
    stage: 'post-treatment',
    type: 'preservation',
    puberty: 'have',
  },
  { 
    value: 'longTermSpermStorage', 
    label: 'Long-Term Sperm Storage',
    gender: 'male',
    stage: 'post-treatment',
    type: 'preservation',
    puberty: 'have',
  },
  { 
    value: 'fertilityTesting', 
    label: 'Fertility Assessment',
    gender: '',
    stage: 'pre-treatment',
    type: 'building',
    puberty: 'have',
  },
  { 
    value: 'donorSperm', 
    label: 'Donor Sperm',
    gender: '',
    stage: '',
    type: 'building',
    puberty: 'have',
  },
  { 
    value: 'surgicalSpermRetrieval', 
    label: 'Surgical Sperm Retrieval',
    gender: 'male',
    stage: '',
    type: 'preservation',
    puberty: 'have',
  },
  { 
    value: 'donorEggs', 
    label: 'Donor Eggs',
    gender: 'female',
    stage: '',
    type: 'building',
    puberty: 'have',
  },
  { 
    value: 'uterusTransplant', 
    label: 'Uterus Transplant',
    gender: 'female',
    stage: '',
    type: 'preservation',
    puberty: 'have',
  },
  { 
    value: 'gestationalSurrogacy', 
    label: 'Gestational Surrogacy',
    gender: '',
    stage: '',
    type: 'building',
    puberty: 'have',
  },
  { 
    value: 'adoption', 
    label: 'Adoption',
    gender: '',
    stage: '',
    type: 'bulding',
    puberty: '',
  }
];

export { 
  gender,
  stage,
  type,
  puberty,
  services,
};